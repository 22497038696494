html {
  overflow-y: scroll;
}

.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fafafa;
}

footer {
  margin-top: auto;
}

.navbar {
  margin-top: 1rem;
}

.app-logo {
  width: 12rem;
}

.nav-icon {
  /* max-width: 2rem; */
  color: #4a85c5;
  margin-left: 0.5rem;
}

.menu-item-answer:hover {
  cursor: pointer;
  background-color: #99caff;
}
.leaflet-container {
  width: 100%;
  height: 50vh;
  z-index: 0;
}
.upload-menu {
  height: 40em;
  width: 70em;
  overflow-y: scroll;
  background-color: #f2f2f2;
}

.circle {
  display: flex;
  height: 3.5rem;
  aspect-ratio: 1/1;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
}

.circle-content {
  font-size: 1.8rem;
  font-weight: bold;
}

.invalid-input {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
/*
  IMHO this should be implemented by Bootstrap itself (based on the documentation)
  but it is not working correctly.
*/
.float-right {
  float: right;
}

/*
  IMHO hack to force button on the right side.
  the `ml-auto` class is not working there.
*/
.move-right {
  display: flex;
  margin-left: auto;
}

.form-control {
  border-color: #4a85c5;
}

.btn-primary {
  background-color: #4a85c5;
  border-color: #4a85c5;
}

.btn-primary:disabled {
  background-color: #697e96;
  border-color: #697e96;
}

/* This change color to the small down arrow for the menu dropdown */
.navbar-light .navbar-nav .nav-link {
  color: #4a85c5;
}

/*
  IMHO this should be implemented by Bootstrap itself using `ml-auto` (based on the documentation)
  but it is not working correctly for .navbar-nav.
*/
.navbar-nav {
  margin-left: auto;
}

.circular-container {
  position: relative;
  display: inline-flex;
}

.circular-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circular-wrapper > .circular-text {
  font-size: 2rem;
}

.quests-avg {
  top: 60%;
}

.points-avg {
  top: 60%;
}

.dropdown-toggle::after {
  display: none;
}

a {
  text-decoration: none;
  color: #454545;
}

a:hover {
  text-decoration: none;
  color: #262626;
}

.link:hover {
  text-decoration: underline;
}

.game-tile {
  background-color: #99caff;
  box-shadow: 3px 3px 3px 1px #808080;
  border-radius: 8pt;
}

.game-tile:hover {
  background-color: #5cabff;
  box-shadow: 3px 3px 3px 1px #454545;
}

.list-group-item {
  background-color: transparent;
}

.user-icon {
  color: #4a85c5;
}

.delete-icon {
  color: #ff5555;
}

.delete-icon:hover {
  color: #ff0000;
}

.btn-link {
  color: #454545;
}

.btn-link:hover {
  color: #262626;
}

.fa-clickable {
  color: #454545;
}

.fa-clickable:hover {
  color: #262626;
  cursor: pointer;
}

.right-answer {
  color: #008000;
}

.wrong-answer {
  color: #ff0000;
}

.image-modal-content {
  width: auto;
}

.image-modal {
  max-width: none;
  justify-content: center;
}

.image {
  max-height: 85vh;
  max-width: 85vw;
}

.video-player {
  width: 100%;
  height: auto;
  aspect-ratio: 16/9;
}

.scrollable-col {
  height: 50vh;
  overflow-y: auto;
}

.scrollable-answer {
  height: 80vh;
  overflow-y: auto;
}

.switch-container {
  display: flex;
  align-items: center;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 36px;
  margin: 0px 8px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #4a85c5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #4a85c5;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}

/*DASHBOARD TABLE*/
.dashboard-table {
  vertical-align: middle;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.dashboard-table-row {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
}

.dashboard-table tbody td:first-child {
  border-radius: 8px 0 0 8px;
}

.dashboard-table tbody td:last-child {
  border-radius: 0 8px 8px 0;
}

.dashboard-badge {
  width: 72px;
}

.badge-green {
  background-color: #00820d;
}

.badge-yellow {
  background-color: #c08a00;
}

.badge-red {
  background-color: #ab0000;
}
